/* Global scrollbar styling */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #219ebc;
    border-radius: 0;
    /*border: 3px solid #f1f1f1;*/
}

::-webkit-scrollbar-thumb:hover {
    background-color: #023047;
}
